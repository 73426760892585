<template>
    <PageWrapper
        class="employee-dashboard-container"
        :contained="false"
    >
        <NebulaBlockMessage
            v-if="showDirectRequestPaused"
            class="connector-profile__block-message"
            icon="pause"
            color="orange"
            :header="pausedBannerContent.header"
            :description="pausedBannerContent.description"
            dismissible
            @closeBlockMessage="dismissDirectRequestsPaused"
        />
        <NebulaGridRow gutter>
            <NebulaGridBlock
                v-bind="profileAttrs"
                class="connector-profile__profile-card"
            >
                <EmployeeProfile
                    :loading="loading.profile"
                    :data="employeeData"
                    :selfView="selfView"
                />
            </NebulaGridBlock>
            <NebulaGridBlock
                :columns="6"
                :mdSize="8"
                v-if="!flags['calendar-removed']"
                class="connector-profile__availability-card"
            >
                <Availability
                    v-if="employeeData"
                    :showColumn="false"
                    :employeeId="employeeId"
                />
            </NebulaGridBlock>
        </NebulaGridRow>

        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <PageSection
                    class="page-section--profile-cards"
                    :type="relatedConnectors.length > 0 ? 'flat' : 'content-raised'"
                    title="Related Connectors"
                    :viewAll="{ show: true, link: orgMembersLink }"
                >
                    <CardBlock
                        v-if="relatedConnectors && relatedConnectors.length > 0"
                        :cardList="relatedConnectors"
                        :cardCount="4"
                        cardType="profile"
                        :loading="loading.relatedConnectors"
                    />
                    <EmptyState
                        v-else
                        icon="users"
                        title="Looks like we don't have related Connectors yet."
                        description="Active employees will appear here."
                    />
                </PageSection>
            </NebulaGridBlock>
        </NebulaGridRow>
        <OrgResourcesRow
            v-if="organizationId"
            v-bind="{ selfView }"
            :category="organizationId"
        />
    </PageWrapper>
</template>

<script>
import { NebulaGrid, NebulaBlockMessage } from '@discoveryedu/nebula-components';
import Availability from '@/components/Profile/Availability.vue';
import CardBlock from '@/components/shared/cards/CardBlock.vue';
import EmployeeProfile from '@/components/dashboard/EmployeeProfile.vue';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import { partnerRoles } from '@/constants';
import scrollToTop from '@/mixins/scrollToTop';
import { resourceCardsData } from '@/data/resourceCards';
import convertTextToLink from '@/utils/convertTextToLink';

import createPayload from '@/mixins/data/createPayload';
import removeSelf from '@/mixins/removeSelf';

export default {
    name: 'EmployeeProfilePublic',
    components: {
        NebulaBlockMessage,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        Availability,
        CardBlock,
        EmployeeProfile,
        EmptyState,
        PageSection,
        PageWrapper,
        OrgResourcesRow,
    },
    mixins: [createPayload, removeSelf, scrollToTop],
    data() {
        return {
            resourceCardsData,
            orgMembersLink: undefined,
            organizationId: undefined,
            loading: {
                relatedConnectors: true,
                profile: true,
            },
            directRequestBannerDismissed: false,
        };
    },
    computed: {
        employeeId() {
            return this.$route.params.id;
        },
        employeeData() {
            return this.$store.state.page.employee.data;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        selfView() {
            if (!this.$store.state.page.employee.data) {
                return false;
            }
            return this.$store.state.page.employee.data.employeeId === this.$store.state.user.data.employeeId;
        },
        pausedBannerContent() {
            if (!this.showDirectRequestPaused) {
                return null;
            }

            let description = this.$t('connector-availability-paused-description', { ns: 'employeeprofile' });
            let header = this.$t('connector-availability-paused-header', { ns: 'employeeprofile' });

            if (this.selfView && partnerRoles.includes(this.appMode)) {
                description = this.$t('self-view-availability-paused-description', { ns: 'employeeprofile' });
                header = this.$t('self-view-availability-paused-header', { ns: 'employeeprofile' });
            }

            return {
                description,
                header,
            };
        },
        relatedConnectors() {
            const cleaned = this.removeUsers(this.$store.state.page.employees, [this.employeeId]);
            return cleaned.slice(0, 4);
        },
        flags() {
            return this.$store.getters.flags;
        },
        showDirectRequestPaused() {
            if (!this.employeeData) {
                return false;
            }

            if (partnerRoles.includes(this.appMode) && !this.selfView) {
                return false;
            }

            if (!this.flags['pause-direct-requests'] || this.directRequestBannerDismissed) {
                return false;
            }

            const settingKey = 'direct_requests_active';
            const set = Object.keys(this.employeeData).includes(settingKey);
            if (set) {
                return !this.employeeData?.direct_requests_active;
            }

            return false;
        },
        profileAttrs() {
            let attrs = {
                columns: 6,
                mdSize: 8,
            };
            if (this.flags['calendar-removed']) {
                attrs = {
                    columns: 8,
                    offset: 2,
                    mdOffset: 0,
                };
            }

            return attrs;
        },
    },
    methods: {
        async loadData() {
            const payload = await this.createPayload({ employeeId: this.employeeId });
            await this.$store.dispatch('viewEmployee', payload);
            this.loading.profile = false;

            const employeeOrgs = this.$store.getters.profileEmployeeOrgs.map((each) => each.organization_id);

            // TODO update this to filter multiple orgs if educator
            const [firstOrg] = employeeOrgs;
            this.organizationId = firstOrg;
            this.orgMembersLink = `/${firstOrg}/connectors`;

            const toConvert = [];
            employeeOrgs.forEach((organizationId) => toConvert.push(['organization', organizationId]));
            toConvert.push(['limit', 5], ['sort', 'random']);
            const params = new URLSearchParams(toConvert).toString();
            const relatedPayload = await this.createPayload({ params });
            await this.$store.dispatch('getEmployees', relatedPayload);
            this.loading.relatedConnectors = false;
        },
        dismissDirectRequestsPaused() {
            this.directRequestBannerDismissed = true;
        },
        activateBannerEmail() {
            convertTextToLink(
                '.nebula-block-message__text',
                [{
                    text: 'careerconnect@DiscoveryEd.com',
                    className: 'connector-profile__banner-link',
                    url: 'mailto:careerconnect@DiscoveryEd.com',
                },
                {
                    text: 'My Account > Settings',
                    className: 'connector-profile__banner-link',
                    url: '/settings',
                }],
            );
        },
    },
    async mounted() {
        this.scrollToTop();
        this.loadData();
        if (this.showDirectRequestPaused) {
            this.activateBannerEmail();
        }
    },
    watch: {
        employeeId() {
            this.scrollToTop();
            this.loadData();
        },
        showDirectRequestPaused(updated) {
            if (updated) {
                this.$nextTick(() => {
                    this.activateBannerEmail();
                });
            }
        },
    },
    provide: {
        domainType: 'employee',
        pageType: 'profile',
    },
    beforeDestroy() {
        this.$store.dispatch('resetPageData');
    },
};
</script>

<style lang="stylus">
.connector-profile {
    &__profile-card {
        card-base();
        overflow: visible;
    }
    &__availability-card {
        padded-card();
    }

    &__block-message {
        margin-block-end: $nebula-space-5x;
        max-width: unset;
    }
    &__banner-link {
        link();
    }
}
</style>
